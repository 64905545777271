<template>
  <Page pageTitle="Tasks"  customClass="is-projects mt-6">
     <!-- Row -->
      <Columns>
        <Column>
          Project detail
        </Column>
        <Column>
        </Column>
      </Columns>
      <!-- Row -->

    <template #rightbar>
      <h4>sidebar</h4>
    </template>

  </Page>
</template>

<script>
  import { defineAsyncComponent } from 'vue'

  export default {
    components: {
      Page: defineAsyncComponent(() => import('@/components/layout/Page.vue')),
      Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
      Columns: defineAsyncComponent(() => import('@/components/ds/Columns')),
    },
  }
</script>

<style lang="scss" scoped>

</style>